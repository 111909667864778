import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import "url-search-params-polyfill";
import Loadable from "react-loadable";

import "styles/App.css";

import EnvironmentService from "service/environment/EnvironmentService";
import EnvironmentConfig from "service/environment/EnvironmentConfig";
import AuthenticationService from "service/auth/AuthenticationService";
import EventService from "service/event/EventService";
import Helper from "util/Helper";

import ProtectedRoute from "components/route/ProtectedRoute";
import ScrollToTop from "components/common/ScrollToTop";
import GlobalError from "components/common/GlobalError";

import Drawer from "components/drawer/Drawer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoPageFound from "modules/NoPageFound";
import DoctorDrawer from "components/drawer/DoctorDrawer";
import NavigoDashboard from "./modules/misc/modules/navigo-dashboard";
import ClevertapReact from "clevertap-react";
import clevertap from "clevertap-web-sdk";
import ClaimsDashboard from "./modules/misc/modules/navigo-dashboard/components/claims-dashboard";

const LoadingComponent = (props) => {
  if (props.error)
    return EventService.emit("showError", {
      message: "Error loading the module",
    });

  if (props.pastDelay) return <h3> loading... </h3>;
  return null;
};
const Employee = Loadable({
  loader: () => import("modules/misc/modules/employee"),
  loading: LoadingComponent,
});
const MedicineOrders = Loadable({
  loader: () => import("modules/ops/modules/medicine-orders"),
  loading: LoadingComponent,
});
const PdfDialog = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/new-bridge/modules/detailed-assessment/components/PdfDialog"
    ),
  loading: LoadingComponent,
});
const HealthGoals = Loadable({
  loader: () => import("./modules/misc/modules/health-goals"),
  loading: LoadingComponent,
});

const Escalations = Loadable({
  loader: () => import("modules/ops/modules/escalations"),
  loading: LoadingComponent,
});

const SelectTenant = Loadable({
  loader: () => import("modules/user/components/SelectTenant"),
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import("modules/user/components/Login"),
  loading: LoadingComponent,
});
const DoctorLogin = Loadable({
  loader: () => import("modules/user/components/LoginAsDoctor"),
  loading: LoadingComponent,
});

const Profile = Loadable({
  loader: () => import("modules/user/components/Profile"),
  loading: LoadingComponent,
});

const Dashboard = Loadable({
  loader: () => import("modules/dashboard/components/Dashboard"),
  loading: LoadingComponent,
});

const Doctors = Loadable({
  loader: () => import("modules/sales/modules/doctors/components/Doctors"),
  loading: LoadingComponent,
});

const CareManager = Loadable({
  loader: () => import("modules/ops/modules/care-manager"),
  loading: LoadingComponent,
});

const Consultations = Loadable({
  loader: () =>
    import("modules/ops/modules/consultations/components/Consultations"),
  loading: LoadingComponent,
});

const Feedbacks = Loadable({
  loader: () => import("modules/ops/modules/feedbacks/components/Feedbacks"),
  loading: LoadingComponent,
});

const Communications = Loadable({
  loader: () =>
    import("modules/ops/modules/communications/components/Communications"),
  loading: LoadingComponent,
});

const Patient = Loadable({
  loader: () => import("modules/ops/modules/patients/components/Patient"),
  loading: LoadingComponent,
});

const Miscellaneous = Loadable({
  loader: () => import("modules/misc/components/Miscellaneous"),
  loading: LoadingComponent,
});

const Units = Loadable({
  loader: () => import("modules/misc/modules/units/components/Units"),
  loading: LoadingComponent,
});

const Cuisines = Loadable({
  loader: () => import("modules/misc/modules/cuisines/components/Cuisines"),
  loading: LoadingComponent,
});

const Params = Loadable({
  loader: () => import("modules/misc/modules/params/components/Params"),
  loading: LoadingComponent,
});

const ParamGroups = Loadable({
  loader: () => import("modules/misc/modules/params/modules/groups"),
  loading: LoadingComponent,
});

const GoalsMaster = Loadable({
  loader: () => import("modules/misc/modules/goals/components/GoalsMaster"),
  loading: LoadingComponent,
});

const TasksMaster = Loadable({
  loader: () => import("modules/misc/modules/tasks/components/TasksMaster"),
  loading: LoadingComponent,
});

const ACL = Loadable({
  loader: () => import("modules/misc/modules/acl/components/ACL"),
  loading: LoadingComponent,
});

const InformationCards = Loadable({
  loader: () =>
    import("modules/ops/modules/information-cards/components/InformationCards"),
  loading: LoadingComponent,
});

const AddEditInformationCard = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/information-cards/components/AddEditInformationCard"
    ),
  loading: LoadingComponent,
});

const ChecklistMasters = Loadable({
  loader: () =>
    import("modules/misc/modules/checklists/components/ChecklistMasters"),
  loading: LoadingComponent,
});

const ManagePatientTracking = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/patients/modules/tracking/components/ManagePatientTracking"
    ),
  loading: LoadingComponent,
});

const PatientTracking = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/patients/modules/tracking/components/PatientTracking"
    ),
  loading: LoadingComponent,
});

const DoctorBridgeView = Loadable({
  loader: () =>
    import("modules/ops/modules/patients/modules/doctor-bridge/index"),
  loading: LoadingComponent,
});

const ReportsAndVitalsCareManager = Loadable({
  loader: () => import("modules/ops/modules/patients/modules/new-bridge/index"),
  loading: LoadingComponent,
});

const ReportsAndVitals = Loadable({
  loader: () => import("modules/ops/modules/patients/modules/new-bridge/index"),
  loading: LoadingComponent,
});

const AddEditEmployee = Loadable({
  loader: () => import("modules/user/components/AddEditEmployee"),
  loading: LoadingComponent,
});

const Games = Loadable({
  loader: () => import("modules/recreation/modules/games"),
  loading: LoadingComponent,
});
const Protocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Proto"),
  loading: LoadingComponent,
});

const DiseasesProtocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Pages/DiseasesProtocols"),
  loading: LoadingComponent,
});
const SymptomsProtocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Pages/SymptomsProtocols"),
  loading: LoadingComponent,
});
const AcuteconditionsProtocols = Loadable({
  loader: () =>
    import("modules/ops/modules/Protocols/Pages/AcuteconditionsProtocols"),
  loading: LoadingComponent,
});
const CareManagerProtocols = Loadable({
  loader: () =>
    import("modules/ops/modules/Protocols/Pages/CareManagerProtocols"),
  loading: LoadingComponent,
});

const MedicalConditions = Loadable({
  loader: () =>
    import(
      "./modules/misc/modules/medicalConditions/components/MedicalConditions"
    ),
  loading: LoadingComponent,
});

const Constituents = Loadable({
  loader: () =>
    import("./modules/misc/modules/constituents/components/Constituents"),
  loading: LoadingComponent,
});

const DishesMaster = Loadable({
  loader: () => import("./modules/misc/modules/dishes/components/DishesMaster"),
  loading: LoadingComponent,
});

const Sales = Loadable({
  loader: () => import("modules/sales/modules/patients/"),
  loading: LoadingComponent,
});

const Patients = Loadable({
  loader: () => import("modules/ops/modules/patients/"),
  loading: LoadingComponent,
});

const DoctorPatients = Loadable({
  loader: () => import("modules/ops/modules/patients/DoctorPatient"),
  loading: LoadingComponent,
});

const NewAppFeature = Loadable({
  loader: () => import("./modules/misc/modules/new-app-feature"),
  loading: LoadingComponent,
});

const HealthPlan = Loadable({
  loader: () => import("./modules/misc/modules/health-plan"),
  loading: LoadingComponent,
});
const NewHealthPlanItems = Loadable({
  loader: () => import("./modules/misc/modules/new-health-plan-items"),
  loading: LoadingComponent,
});

const HealthPlanOption = Loadable({
  loader: () => import("./modules/misc/modules/health-plan-option"),
  loading: LoadingComponent,
});
const NewHealthPlanOption = Loadable({
  loader: () => import("./modules/misc/modules/new-health-plan-option"),
  loading: LoadingComponent,
});

const HealthPlanTemplate = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/new-bridge/modules/health-plan"
    ),
  loading: LoadingComponent,
});
const Prescription = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/new-bridge/modules/prescription"
    ),
  loading: LoadingComponent,
});
const CouponGenerator = Loadable({
  loader: () => import("./modules/ops/modules/coupon-generator"),
  loading: LoadingComponent,
});
const BdAutomation = Loadable({
  loader: () => import("./modules/ops/modules/bd-automation"),
  loading: LoadingComponent,
});

const PlanAssignment = Loadable({
  loader: () => import("./modules/ops/modules/patients/modules/planAssignment"),
  loading: LoadingComponent,
});

const PatientProgram = Loadable({
  loader: () => import("./modules/ops/modules/patient-program/index"),
  loading: LoadingComponent,
});
const User = Loadable({
  loader: () => import("./modules/misc/modules/user"),
  loading: LoadingComponent,
});
const LabtestBooking = Loadable({
  loader: () => import("./modules/misc/modules/labtest-bookings"),
  loading: LoadingComponent,
});
const DocViewer = Loadable({
  loader: () => import("./modules/ops/modules/DocuementViewer/index"),
  loading: LoadingComponent,
});

const RecipeDashboard = Loadable({
  loader: () => import("./modules/misc/modules/recipe-dashboard"),
  loading: LoadingComponent,
});
const GuidelineDashboard = Loadable({
  loader: () => import("./modules/misc/modules/guidelines-dashboard"),
  loading: LoadingComponent,
});
const MedicineDashboard = Loadable({
  loader: () => import("./modules/misc/modules/medicine-dashboard"),
  loading: LoadingComponent,
});

const Whitelisting = Loadable({
  loader: () => import("../src/modules/misc/modules/whitelisting"),
  loading: LoadingComponent,
});
class App extends Component {
  constructor() {
    super();
    this.environment = this.getEnvironment();
    this.environmentService = new EnvironmentService();
    this.environmentService.set(this.environment);

    this.authenticationService = new AuthenticationService();
    this.helper = new Helper();

    this.search = new URLSearchParams(window.location.search);

    // initialize Google Analytics only if production
    if (this.environmentService.isProduction()) {
      ReactGA.initialize("UA-96177847-2");
      this.logPageView();
    }

    this.state = {
      user: this.authenticationService.getUserProfile(),
      who: this.getWhoLoggedIn(),
    };
  }
  getWhoLoggedIn() {
    const user = this.authenticationService.getUserProfile();
    let who = "EMPLOYEE";
    if (user && user.patientProfile && user.patientProfile.id > 0) {
      who = "PATIENT";
    } else if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      who = "EMPLOYEE";
    } else if (
      user &&
      user.employeeProfile &&
      user.employeeProfile.id > 0 &&
      user.doctorProfile &&
      user.doctorProfile.id > 0
    ) {
      who = "EMPLOYEE";
    } else if (user && user.doctorProfile && user.doctorProfile.id > 0) {
      who = "DOCTOR";
    } else {
      who = "EMPLOYEE";
    }
    return who;
  }
  componentWillMount() {
    this.getWhoLoggedIn();
    this.setState({
      user: this.authenticationService.getUserProfile(),
      who: this.getWhoLoggedIn(),
    });
    // this.initializeCleverTap(this.environment);
    this.initializeCleverTapAB(this.environment);
  }

  initializeCleverTapAB = (env) => {
    const cleverTapProdAB = "6KK-WK4-695Z";
    const cleverTapStagAB = "TEST-7KK-WK4-695Z";
    let clevertapId = "TEST-7KK-WK4-695Z";
    if (env === "production") {
      clevertapId = "6KK-WK4-695Z";
    }
    // ClevertapReact.initialize(clevertapId);
    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(clevertapId, "in1"); // Replace with values applicable to you. Refer below
  };

  initializeCleverTap = (env) => {
    const cleverTapProd = "677-954-R66Z";
    const cleverTapStag = "TEST-777-954-R66Z";
    let clevertapId = "TEST-777-954-R66Z";
    if (env === "production") {
      clevertapId = "677-954-R66Z";
    }

    ClevertapReact.initialize(clevertapId);
  };
  getEnvironment() {
    let config = EnvironmentConfig;
    let originUrl = window.location.hostname;

    if (
      window.location.hostname == "dev1.bridge.zyla.in" ||
      window.location.hostname == "dev1-sy-bridge.zyla.in" ||
      window.location.hostname == "dev3-bridge.zyla.in" ||
      window.location.hostname == "staging-sy-bridge.zyla.in"
    ) {
      return "staging";
    }
    if (
      window.location.hostname == "bridge.zyla.in" ||
      window.location.hostname == "bridge.prod.zyla.in" ||
      window.location.hostname == "sy-bridge.zyla.in"
    ) {
      return "production";
    }

    for (var environment in config.vars) {
      let domain = config.vars[environment].domain;
      if (domain == originUrl) {
        return config.environments[environment];
      }
    }
  }

  logPageView() {
    ReactGA.set({ page: "home" });
    ReactGA.pageview("home");
  }

  render() {
    const updateStatus = localStorage.getItem("updateStatus");
    const updateMessage = localStorage.getItem("updateMessage");
    const { who } = this.state;

    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <div>
              <Switch>
                <Route path="/tenant" component={SelectTenant} />
                <Route path="/login" component={Login} />
                <Route path="/doctor/login" component={DoctorLogin} />
                <Route path="/">
                  <div>
                    {who && who === "DOCTOR" && (
                      <DoctorDrawer>
                        <ProtectedRoute
                          path="/"
                          component={DoctorPatients}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/me"
                          component={Profile}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/patients/:patientId/new-bridge"
                          component={DoctorBridgeView}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                      </DoctorDrawer>
                    )}

                    {who && who === "EMPLOYEE" && (
                      <Drawer>
                        <div className="container-main">
                          <ProtectedRoute
                            path="/"
                            component={Dashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/protocols"
                            component={Protocols}
                            authenticator={this._authenticator}
                            redirectTo={"/"}
                          />
                          <ProtectedRoute
                            path="/protocols/diseasesprotocols"
                            component={DiseasesProtocols}
                            authenticator={this._authenticator}
                            redirectTo={"/protocols"}
                          />
                          <ProtectedRoute
                            path="/protocols/symptomsprotocols"
                            component={SymptomsProtocols}
                            authenticator={this._authenticator}
                            redirectTo={"/protocols"}
                          />
                          <ProtectedRoute
                            path="/protocols/caremanagerprotocols"
                            component={CareManagerProtocols}
                            authenticator={this._authenticator}
                            redirectTo={"/protocols"}
                          />
                          <ProtectedRoute
                            path="/protocols/acuteconditionsprotocols"
                            component={AcuteconditionsProtocols}
                            authenticator={this._authenticator}
                            redirectTo={"/protocols"}
                          />
                          <ProtectedRoute
                            path="/me"
                            component={Profile}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/employees/new"
                            component={AddEditEmployee}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/doctors"
                            component={Doctors}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients"
                            component={Patients}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/sales"
                            component={Sales}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/consultations"
                            component={Consultations}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/feedbacks"
                            component={Feedbacks}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/communications/:phoneNo?"
                            component={Communications}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/analytics"
                            component={Consultations}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients/:patientId"
                            component={Patient}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients/:patientId/manage"
                            component={ManagePatientTracking}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients/:patientId/track"
                            component={PatientTracking}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients/:patientId/new-bridge"
                            component={ReportsAndVitals}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/care-manager/:patientId/new-bridge"
                            component={ReportsAndVitalsCareManager}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/care-manager"
                            component={CareManager}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/medicine-orders"
                            component={MedicineOrders}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/information-cards"
                            component={InformationCards}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/information-cards/new"
                            component={AddEditInformationCard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/information-cards/edit/:id"
                            component={AddEditInformationCard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous"
                            component={Miscellaneous}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/health-goals"
                            component={HealthGoals}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/units"
                            component={Units}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/params"
                            component={Params}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/params/groups"
                            component={ParamGroups}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/goals/master"
                            component={GoalsMaster}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/tasks/master"
                            component={TasksMaster}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/checklists/master"
                            component={ChecklistMasters}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/acl"
                            component={ACL}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/cuisines"
                            component={Cuisines}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/escalations"
                            component={Escalations}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/medical_conditions"
                            component={MedicalConditions}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/health-plan"
                            component={HealthPlan}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/new-health-plan-items"
                            component={NewHealthPlanItems}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/new-app-feature"
                            component={NewAppFeature}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/health-plan-options"
                            component={HealthPlanOption}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />

                          <ProtectedRoute
                            path="/miscellaneous/new-health-plan-options"
                            component={NewHealthPlanOption}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/health-plan-template"
                            component={HealthPlanTemplate}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/constituents"
                            component={Constituents}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/dish_master"
                            component={DishesMaster}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/labtest-booking"
                            component={LabtestBooking}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />

                          <ProtectedRoute
                            path="/games"
                            component={Games}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/pdf/:patientId/:userId/:userName"
                            component={PdfDialog}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/acl/employee"
                            component={Employee}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/coupon-generator"
                            component={CouponGenerator}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/bd-automation"
                            component={BdAutomation}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/prescription/:userId"
                            component={Prescription}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/acl/user"
                            component={User}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patients/:userId/assign"
                            component={PlanAssignment}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/patient-program"
                            component={PatientProgram}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/medicine-dashboard"
                            component={MedicineDashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/whitelisting"
                            component={Whitelisting}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/recipe-dashboard"
                            component={RecipeDashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/guideline-dashboard"
                            component={GuidelineDashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/navigo-dashboard"
                            component={NavigoDashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                          <ProtectedRoute
                            path="/miscellaneous/claims-dashboard"
                            component={ClaimsDashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                        </div>
                      </Drawer>
                    )}
                    {who && who === "EMPLOYEE" && (
                      <ProtectedRoute
                        path="/patient/:patientId/document/:folder/:startIndex"
                        component={DocViewer}
                        authenticator={this._authenticator}
                        redirectTo={"/login"}
                      />
                    )}
                  </div>
                </Route>

                <Route path="/not-found" component={NoPageFound} />
              </Switch>
              <GlobalError />
            </div>
          </ScrollToTop>
        </Router>
      </div>
    );
  }

  _authenticator = () => {
    return this.authenticationService.isLoggedIn();
  };

  _reverseAuthenticator = () => {
    return !this.authenticationService.isLoggedIn();
  };
}

export default App;
